import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router"
import './styles.css'
import ButtonRectangle from '../../components/buttonRectangle'
import LoadingBar from '../../components/loadingBar'
// import OverMessage from '../../components/overMessage'
// import Footer from '../../components/footer'

import logo from '../../imgs/ld-logo-1.png'

const Intro = () => {
    let history = useHistory()
    const [isLoading, setIsLoading] = useState(true)
    const [overMessage, setOverMessage] = useState({
        show: false,
        message: '',
        type: ''
    })

    const toLogin = async () => {
        history.push('/login')
    }

    const toNewTask = async () => {
        history.push('/atendimento')
    }

    useEffect(() => {
        setIsLoading(false)
    }, [])

    return (
        <div>
            {
                <LoadingBar show={isLoading} />
            }
            <div className="full mainBackgroundGrad loginBackground">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 full center toBottom">
                            <div className="bottom25vh padding30">
                                <div className="row">
                                    <div className="col-12">
                                        <h1 className="mainTitle">Bem-Vindo(a)</h1>
                                        <p className="subTitle">
                                            Agradecemos sua visita,
                                            escolha uma opção.
                                        </p>
                                    </div>
                                    <div className="col-12 col-md-6 top10">
                                        <div onClick={e => toNewTask()}>
                                            <ButtonRectangle label="Registrar Atendimento" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 top10">
                                        <div onClick={e => toLogin()}>
                                            <ButtonRectangle label="Efetuar Login" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="notShowUntilMedium col-12 col-md-6 full center toBottom">
                            <div className="bottom25vh padding30">
                                <img className="img-fluid" src={logo} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default Intro