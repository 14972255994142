import React, { useState, useEffect } from 'react'

//css
import './style.css'

//Service
import rest from '../../service/restAuth'

//Components
import LoadingBar from '../../components/loadingBar'
import MenuBar from '../../components/menuBar'
import Inputmask from 'inputmask'
import OverMessage from '../../components/overMessage'
import Footer from '../../components/footer'

// import 'https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js'
// import 'https://protocolosjjeletrica.com.br/static/js/3.f68336c1.chunk.js'
// import 'https://protocolosjjeletrica.com.br/static/js/main.a83d7195.chunk.js'

export default () => {
    const [isLoading, setIsLoading] = useState(true)
    const [cityID, setCityID] = useState('')

    // useEffect(() => {
    //     fetch("http://localhost:8082/template/v1/pdf" /*, options */)
    //         .then((response) => response.text())
    //         .then((html) => {
    //             document.getElementById("pdfBox").innerHTML = html;
    //         })
    //         .catch((error) => {
    //             console.warn(error);
    //         });
    // }, [])

    useEffect(async () => {
        try {

            const userFromStorage = localStorage.getItem('@lighthouse_jjeletrica:user')
            const user = await JSON.parse(userFromStorage)
            if (parseInt(user.city.id) > 0) {
                setCityID(user.city.id)
            } else {
                setCityID('0')
            }

            setTimeout(() => {
                document.getElementById("cityForm").submit()
                // setIsLoading(false)
            }, 300)
        } catch (error) {

        }
    }, [])

    return (
        <div>
            {
                isLoading && <LoadingBar />
            }
            <div id="top">
                <MenuBar />
            </div>
            <div className='full container' id='pdfBox'>
                <form id='cityForm' name='cityForm' method='POST' action='http://vps29049.publiccloud.com.br:8081/template/v1/pdf'>
                    {/* <form id='cityForm' name='cityForm' method='POST' action='http://localhost:8082/template/v1/pdf'> */}
                    {/* <input id='edtPeriodOne' name='edtPeriodOne' type='hidden' value={new Date().getDay() + '/' + new Date().getMonth() + '/' + new Date().getFullYear()} /> */}
                    <input id='edtCityID' name='edtCityID' type='hidden' value={cityID} />
                    <input id='edtIsLoadData' name='edtIsLoadData' type='hidden' value='false' />
                    {/* <input id='edtPeriodOne' name='edtPeriodOne' type='hidden' value='' />
                    <input id='edtPeriodTwo' name='edtPeriodTwo' type='hidden' value='' />
                    <input id='edtProtocol' name='edtProtocol' type='hidden' value='' />
                    <input id='edtNeighborhood' name='edtNeighborhood' type='hidden' value='' />
                    <input id='edtStreet' name='edtStreet' type='hidden' value='' />
                    <input id='edtUsername' name='edtUsername' type='hidden' value='' />
                    <input id='edtUsername' name='edtUsername' type='hidden' value='' /> */}
                    {/* <input type='submit' value='OK' /> */}
                </form>
            </div>
            <Footer />
        </div >
    )
}
























// import React, { useState, useEffect } from 'react'

// //css
// import './style.css'

// //Service
// import rest from '../../service/restAuth'

// //Components
// import LoadingBar from '../../components/loadingBar'
// import MenuBar from '../../components/menuBar'
// import Inputmask from 'inputmask'
// import OverMessage from '../../components/overMessage'

// // import 'https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js'
// // import 'https://protocolosjjeletrica.com.br/static/js/3.f68336c1.chunk.js'
// // import 'https://protocolosjjeletrica.com.br/static/js/main.a83d7195.chunk.js'

// export default () => {
//     const [isLoading, setIsLoading] = useState(false)
//     const [tasks, setTasks] = useState()
//     const [isShowTable, setIsShowTable] = useState(true)
//     const [isChangeBySQL, setIsChangeBySQL] = useState(false)
//     const [isShowInfosPhotosAndMaterials, setIsShowInfosPhotosAndMaterials] = useState(false)
//     const [isShowInfosAddress, setIsShowInfosAddress] = useState(false)
//     const [overMessage, setOverMessage] = useState({
//         show: false,
//         message: '',
//         type: ''
//     })

//     const dataPrint = () => {
//         // binders()
//         console.log('dataPrint...dataPrint...dataPrint')
//         var eligible = document.querySelectorAll('.js-eligible-for-print');
//         console.log('eligible...')
//         console.log(eligible)

//         if (eligible.length > 0) {
//             var mywindow = window.open('', 'PRINT', 'height=800,width=600');
//             var headTags = document.querySelectorAll("link[rel='stylesheet']");

//             mywindow.document.write('<html><head>');
//             mywindow.document.write('<link rel="stylesheet" href="page-print.css" type="text/css" />');
//             if (headTags.length > 0) {
//                 for (var i = 0; i < headTags.length; i++) {
//                     mywindow.document.write(headTags[i].outerHTML)
//                 }
//             }
//             mywindow.document.write('</head><body>');
//             mywindow.document.write('<div class="page-header text-uppercase">');
//             mywindow.document.write('	<img class="bg" src="img/jj-header.png" />');
//             mywindow.document.write('	<div class="col-6 h-100 float-left p-2"><img class="logo" src="img/jj-logo.png" /></div>');
//             mywindow.document.write('	<div class="col-6 h-100 float-right p-2 text-right">CNPJ: 29.793.736/0001-46 <br> IE: 258.598.190 | IM: 41457</div>');
//             mywindow.document.write('</div>');
//             mywindow.document.write('<div class="page-footer">');
//             mywindow.document.write('	<img class="bg" src="img/jj-footer.png" />');
//             mywindow.document.write('	<div class="p-2 text-right">Rua: Ouro preto, 373 - Bairro Benedito - Indaial/SC <br> Fone/Fax: (47) 3333-2652 <br> E-mail: jjinstaladora@outlook.com</div>');
//             mywindow.document.write('</div>');

//             for (var i = 0; i < eligible.length; i++) {
//                 var pageNumber = i + 1;
//                 mywindow.document.write('<page>');
//                 mywindow.document.write(eligible[i].querySelector('.js-print-page').outerHTML);
//                 mywindow.document.write('<strong class="text-muted page-number">Pag. ' + pageNumber + '</strong>');
//                 mywindow.document.write('</page>');
//                 mywindow.document.write('<div class="page-break"></div>');
//             }

//             mywindow.document.write('</body></html>');

//             mywindow.document.close();
//             mywindow.focus();

//             mywindow.onload = function () {
//                 mywindow.print();
//                 // mywindow.close();
//             };

//             return true;
//         }
//     }

//     const findTasks = async () => {
//         try {
//             setIsLoading(true)
//             const response = await rest.get('/helpdesk/v1/findAll')
//             console.log('response.data')
//             console.log(response.data)
//             setTasks(response.data)
//             setIsLoading(false)
//             setIsChangeBySQL(true)
//         } catch (e) {
//             setIsLoading(false)
//         }
//     }

//     const filterByDate = async () => {
//         try {
//             let validPeriodOne = document.getElementById('edtPeriodOne').value
//             let validPeriodTwo = document.getElementById('edtPeriodTwo').value
//             if (validPeriodOne == '' && validPeriodTwo == '') {// sem nenhum filtro, traz a base toda
//                 findTasks()
//             } else if (validPeriodOne == '' || validPeriodTwo == '') {// não filtra pq precisa informar as duas datas(de/até)
//                 setOverMessage({
//                     show: true,
//                     message: 'Favor informar as duas datas (de/até)',
//                     type: 'error'
//                 })

//                 setTimeout(() => {
//                     setOverMessage({
//                         show: false,
//                         message: '',
//                         type: ''
//                     })
//                 }, 3000)
//             } else {//filtra por período
//                 setIsLoading(true)
//                 const response = await rest.get(`/helpdesk/v1/findAllByPeriod/${validPeriodOne}-${validPeriodTwo}`)
//                 setTasks(response.data ? response.data : [])
//                 setIsLoading(false)
//                 setIsChangeBySQL(true)
//             }
//         } catch (e) {
//             setIsLoading(false)
//         }
//     }

//     window.onscroll = function (oEvent) {
//         checkHeadTable()
//     }

//     const checkHeadTable = () => {
//         if (window.pageYOffset >= 100) {
//             // document.getElementById('headLineTableId').classList.add('topFixed')
//         } else {
//             // document.getElementById('headLineTableId').classList.remove('topFixed')
//         }
//     }

//     useEffect(() => {
//         let fieldPeriodOne = document.getElementById("edtPeriodOne") ? document.getElementById("edtPeriodOne") : null
//         let fieldPeriodTwo = document.getElementById("edtPeriodTwo") ? document.getElementById("edtPeriodTwo") : null

//         var imDate = new Inputmask("99/99/9999")
//         if (fieldPeriodOne != null) imDate.mask(fieldPeriodOne)
//         if (fieldPeriodTwo != null) imDate.mask(fieldPeriodTwo)
//         findTasks()
//     }, [])

//     useEffect(() => {
//         if (isChangeBySQL) {
//             setIsLoading(true)
//             setIsShowTable(false)
//             setTimeout(() => {
//                 setIsLoading(false)
//                 setIsShowTable(true)
//                 setIsChangeBySQL(false)
//             }, 300)
//         }
//     }, [isChangeBySQL])

//     function binders() {
//         console.log('binders...')
//         let e = document.querySelector('.js-check-all')
//         console.log('e...e')
//         console.log(e)
//         document.querySelector('.js-check-all').addEventListener('click', toggleAllCheckboxes);
//         document.querySelectorAll('.js-select-row').forEach(function (checkbox) {
//             checkbox.addEventListener('click', function (e) {
//                 console.log(' checkbox.addEventListener(click, function (e) {')
//                 toggleEligibleForPrint(this.closest('tr').nextElementSibling, this.checked);
//                 togglePrintButton();
//             });
//         });

//         document.querySelectorAll('.js-select-row').forEach(function (checkbox) {
//             checkbox.addEventListener('click', function (e) {
//                 toggleEligibleForPrint(this.closest('tr').nextElementSibling, this.checked);
//                 togglePrintButton();
//             });
//         });

//         document.querySelectorAll('.js-expand-data-row').forEach(function (expand) {
//             expand.addEventListener('click', function (e) {
//                 e.preventDefault();
//                 toggleExpandData(e.currentTarget);
//             });
//         });

//         document.querySelectorAll('.js-print-row-button').forEach(function (print) {
//             print.addEventListener('click', function (e) {
//                 toggleEligibleForPrint(this.closest('tr').nextElementSibling, true);
//                 printData();
//             });
//         });

//         document.querySelectorAll('.js-print-all-button').forEach(function (print) {
//             print.addEventListener('click', function (e) {
//                 printData();
//             });
//         });
//     }

//     function toggleAllCheckboxes(event) {
//         if (!event) return;
//         var checkboxes = document.querySelectorAll('.js-select-row');
//         var source = event.currentTarget;

//         for (var i = 0, n = checkboxes.length; i < n; i++) {
//             checkboxes[i].checked = source.checked;
//             toggleEligibleForPrint(checkboxes[i].closest('tr').nextElementSibling, source.checked);
//         }

//         togglePrintButton();
//     }

//     function toggleEligibleForPrint(el, bool) {
//         if (!el) return;
//         el.classList.toggle('js-eligible-for-print', bool);
//     }

//     function togglePrintButton() {
//         var selecteds = document.querySelectorAll('.js-eligible-for-print');
//         var button = document.querySelector('.js-print-all-button');

//         if (selecteds.length > 1) {
//             button.querySelector('span').textContent = selecteds.length;
//             button.style.visibility = 'visible';
//         } else {
//             button.style.visibility = 'hidden';
//         }
//     }

//     function toggleExpandData(el) {
//         if (!el) return;
//         var expandRow = el.closest('tr').nextElementSibling;
//         var target = expandRow.querySelector('.js-expand-row[data-ref="' + el.dataset.target + '"]');

//         expandRow.querySelectorAll('.js-expand-row.active').forEach(function (expanded) {
//             if (expanded !== target) {
//                 expanded.classList.remove("active");
//             }
//         });

//         if (!target) throw 'Target is undefined';

//         target.classList.toggle("active");
//     }

//     function printData() {
//         console.log('printData...printData...printData')
//         var eligible = document.querySelectorAll('.js-eligible-for-print');

//         if (eligible.length > 0) {
//             var mywindow = window.open('', 'PRINT', 'height=800,width=600');
//             var headTags = document.querySelectorAll("link[rel='stylesheet']");

//             mywindow.document.write('<html><head>');
//             mywindow.document.write('<link rel="stylesheet" href="page-print.css" type="text/css" />');
//             if (headTags.length > 0) {
//                 for (var i = 0; i < headTags.length; i++) {
//                     mywindow.document.write(headTags[i].outerHTML)
//                 }
//             }
//             mywindow.document.write('</head><body>');
//             mywindow.document.write('<div class="page-header text-uppercase">');
//             mywindow.document.write('	<img class="bg" src="img/jj-header.png" />');
//             mywindow.document.write('	<div class="col-6 h-100 float-left p-2"><img class="logo" src="img/jj-logo.png" /></div>');
//             mywindow.document.write('	<div class="col-6 h-100 float-right p-2 text-right">CNPJ: 29.793.736/0001-46 <br> IE: 258.598.190 | IM: 41457</div>');
//             mywindow.document.write('</div>');
//             mywindow.document.write('<div class="page-footer">');
//             mywindow.document.write('	<img class="bg" src="img/jj-footer.png" />');
//             mywindow.document.write('	<div class="p-2 text-right">Rua: Ouro preto, 373 - Bairro Benedito - Indaial/SC <br> Fone/Fax: (47) 3333-2652 <br> E-mail: jjinstaladora@outlook.com</div>');
//             mywindow.document.write('</div>');

//             for (var i = 0; i < eligible.length; i++) {
//                 var pageNumber = i + 1;
//                 mywindow.document.write('<page>');
//                 mywindow.document.write(eligible[i].querySelector('.js-print-page').outerHTML);
//                 mywindow.document.write('<strong class="text-muted page-number">Pag. ' + pageNumber + '</strong>');
//                 mywindow.document.write('</page>');
//                 mywindow.document.write('<div class="page-break"></div>');
//             }

//             mywindow.document.write('</body></html>');

//             mywindow.document.close();
//             mywindow.focus();

//             mywindow.onload = function () {
//                 mywindow.print();
//                 // mywindow.close();
//             };

//             return true;
//         }
//     }

//     useEffect(() => {
//         binders()
//     }, [])

//     return (
//         <div>
//             {
//                 isLoading && <LoadingBar />
//             }
//             <OverMessage show={overMessage.show} message={overMessage.message} type={overMessage.type} />
//             <div id="top">
//                 <MenuBar />
//             </div>
//             <div></div>
//             {/* <div id="top">
//                 <div>
//                     <nav id="menuFixed" class="navbar fixed-top navbar-expand-lg nav-light menu_nav_bar delay">
//                         <a class="navbar-brand" href="#top"><img src="https://protocolosjjeletrica.com.br/static/media/logojj.f407aaf2.png" class="logoImg" /></a><button class="navbar-toggler blueMask01" type="button" data-toggle="collapse" data-target="#menuBar" aria-controls="menuBar" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon center topLess1">-</span></button>
//                         <div class="collapse navbar-collapse" id="menuBar">
//                             <ul class="navbar-nav ml-auto">
//                                 <li class="nav-item active menu_options"><a class="nav-link" href="/chamados">Chamados</a></li>
//                                 <li class="nav-item active menu_options"><a class="nav-link" href="/material ">Materiais</a></li>
//                                 <li class="nav-item active menu_options"><a class="nav-link" href="/pdfrelatorio ">Relatorio Atendimentos</a></li>
//                                 <li class="nav-item dropdown menu_options">
//                                     <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Opções</a>
//                                     <div class="dropdown-menu" aria-labelledby="navbarDropdown"><a class="dropdown-item">Sair</a></div>
//                                 </li>
//                             </ul>
//                         </div>
//                     </nav>
//                 </div>
//             </div> */}
//             <div class="full padding100Top container">
//                 <div class="row top40">
//                     <div class="col-12">
//                         <div class="row">
//                             <div class="col-8">
//                                 <div class="row">
//                                     <div class="offset-lg-4 col-12 col-lg-10">
//                                         <div class="row">
//                                             <div class="row">
//                                                 <p>Data de atendimento</p>
//                                             </div>
//                                             <div class="row">
//                                                 <div class="col-12 col-md-3 top10">
//                                                     <div class="inputBox center padding03 delay" id="boxedtPeriodOne"><span class="iconBoxField"><i></i></span><input type="text" id="edtPeriodOne" name="edtPeriodOne" placeholder="Data" class="input" value="" inputmode="text" /></div>
//                                                 </div>
//                                                 <div class="col-12 col-md-3 top10">
//                                                     <div class="inputBox center padding03 delay" id="boxedtPeriodTwo"><span class="iconBoxField"><i></i></span><input type="text" id="edtPeriodTwo" name="edtPeriodTwo" placeholder="Data" class="input" value="" inputmode="text" /></div>
//                                                 </div>
//                                                 <div class="col-12 col-md-3">
//                                                     <div class="padding08">
//                                                         <div>
//                                                             <button type="button" class="btn btn-primary btn-block padding10 delay">
//                                                                 <div>Filtrar</div>
//                                                             </button>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                     <div class="col-12 mt-5">
//                         <div class="text-right mb-2">
//                             <button class="btn btn-sm btn-primary js-print-all-button" style={{ visibility: 'hidden' }}>
//                                 <i class="align-middle p-button-icon p-c pi pi-print"></i>	 Imprimir selecionados (<span></span>)
//                             </button>
//                         </div>
//                         <div class="table-responsive custom-table-responsive">
//                             <table class="table custom-table text-center">
//                                 <thead>
//                                     <tr>
//                                         <th scope="col">
//                                             <input type="checkbox" class="js-check-all" title="Selecionar todos para impressão" />
//                                         </th>
//                                         <th scope="col">Protocolo</th>
//                                         <th scope="col">Cliente</th>
//                                         <th scope="col">Ocorrência</th>
//                                         <th scope="col">Data</th>
//                                         <th scope="col">Status</th>
//                                         <th scope="col">Ações</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {/* <!-- ROW GROUP --> */}
//                                     {
//                                         tasks &&
//                                         tasks.map((task, key) => (
//                                             <>
//                                                 <tr scope="row">
//                                                     <th scope="row">
//                                                         <input type="checkbox" class="js-select-row" title="Selecionar linha para imprimir" />
//                                                     </th>
//                                                     <td>
//                                                         {task.protocol}
//                                                     </td>
//                                                     <td>
//                                                         <a href="" class="js-expand-data-row" data-target="client">{task.client.name}</a>
//                                                     </td>
//                                                     <td>{task.problem.problem}</td>
//                                                     <td>
//                                                         <small class="d-block" title="Abertura" ><i class="p-button-icon p-c pi pi-arrow-up"></i>{task.openedDateBR}</small>
//                                                         <small class="d-block" title="Fechamento"><i class="p-button-icon p-c pi pi-arrow-down"></i>{task.finishDateBR}</small>
//                                                     </td>
//                                                     <td>
//                                                         {
//                                                             task.statusHelpDesk.id == 1 &&
//                                                             <i title="Aberto" class="p-button-icon p-c pi pi-flag text-info d-flag d-inline-block"></i>
//                                                         }

//                                                         {
//                                                             task.statusHelpDesk.id == 2 &&
//                                                             <i title="Atrasado" class="p-button-icon p-c pi pi-clock text-warning d-inline-block"></i>
//                                                         }

//                                                         {
//                                                             task.statusHelpDesk.id == 3 &&
//                                                             <i title="Fechado" class="p-button-icon p-c pi pi-check text-success d-inline-block"></i>
//                                                         }
//                                                     </td>
//                                                     <td>
//                                                         <button class="p-button p-component p-button-icon-only js-expand-data-row" data-target="photos" title="Ver fotos" onClick={(e) => { setIsShowInfosPhotosAndMaterials(!isShowInfosPhotosAndMaterials) }}>
//                                                             <i class="p-button-icon p-c pi pi-images"></i>
//                                                         </button>
//                                                         <button class="p-button p-component p-button-icon-only js-expand-data-row" data-target="client" title="Endereço" onClick={(e) => { setIsShowInfosAddress(!isShowInfosAddress) }}>
//                                                             <i class="p-button-icon p-c pi pi-map-marker"></i>
//                                                         </button>
//                                                         {/* <button class="p-button p-component p-button-icon-only js-print-row-button" title="Imprimir" onClick={(e) => { dataPrint() }}> */}
//                                                         <button class="p-button p-component p-button-icon-only js-print-row-button" title="Imprimir">
//                                                             <i class="p-button-icon p-c pi pi-print"></i>
//                                                         </button>
//                                                     </td>
//                                                 </tr>
//                                                 <tr class="expand-row">
//                                                     <td colspan="10">
//                                                         <div class="page js-print-page">
//                                                             {/* <!-- CLIENT DATA --> */}
//                                                             {
//                                                                 isShowInfosAddress &&
//                                                                 <div class="row-extra-data js-expand-row" data-ref="client">
//                                                                     <div class="d-flex w-100">
//                                                                         {/* <!-- ADDRESS --> */}
//                                                                         <div class="col-6">
//                                                                             <div class="card h-100">
//                                                                                 <div class="card-body">
//                                                                                     <div class="d-block mb-3">
//                                                                                         <div class="inline padding08">
//                                                                                             <div class="labelTaskTitle text-uppercase">Endereço</div>
//                                                                                         </div>
//                                                                                         <div class="line"></div>
//                                                                                     </div>

//                                                                                     <div class="row flex-wrap text-left">
//                                                                                         <div class="col-12"><strong class="labelTask mr-1">Contato:</strong></div>
//                                                                                         <div class="col-12"><hr /></div>
//                                                                                         <div class="col-12"><strong class="labelTask mr-1">Rua:</strong>{task.address.street}</div>
//                                                                                         <div class="col-12"><strong class="labelTask mr-1">Cidade:</strong>{task.address.city.name}</div>
//                                                                                         <div class="col-6"><strong class="labelTask mr-1">CEP:</strong>{task.address.zipCode}</div>
//                                                                                         <div class="col-6"><strong class="labelTask mr-1">Bairro:</strong>{task.address.neighborhood}</div>
//                                                                                         <div class="col-6"><strong class="labelTask mr-1">Referência:</strong>{task.address.referencePoint}</div>
//                                                                                         <div class="col-6"><strong class="labelTask mr-1">Complemento:</strong>{task.address.complement}</div>
//                                                                                         <div class="col-6"><strong class="labelTask mr-1">Lote:</strong>{task.address.lot}</div>
//                                                                                     </div>

//                                                                                 </div>
//                                                                             </div>
//                                                                         </div>

//                                                                         {/* <!-- EVENT --> */}
//                                                                         <div class="col-6">
//                                                                             <div class="card h-100">
//                                                                                 <div class="card-body">
//                                                                                     <div class="d-block mb-3">
//                                                                                         <div class="inline padding08">
//                                                                                             <div class="labelTaskTitle text-uppercase">Chamado</div>
//                                                                                         </div>
//                                                                                         <div class="line"></div>
//                                                                                     </div>

//                                                                                     <div class="row flex-wrap text-left">
//                                                                                         <div class="col-12"><strong class="labelTask mr-1">Protocolo:</strong>{task.protocol}</div>
//                                                                                         <div class="col-12"><hr /></div>
//                                                                                         <div class="col-6"><strong class="labelTask mr-1">Ocorrência:</strong>{task.problem.problem}</div>
//                                                                                         <div class="col-6"><strong class="labelTask mr-1">Status:</strong>{task.statusHelpDesk.status}</div>
//                                                                                         <div class="col-12"><strong class="labelTask mr-1">Descrição:</strong>{task.description}</div>
//                                                                                         <div class="col-12"><hr /></div>
//                                                                                         <div class="col-6"><strong class="labelTask mr-1">Abertura:</strong>{task.openedDateBR}</div>
//                                                                                         <div class="col-6"><strong class="labelTask mr-1">Fechamento:</strong>{task.finishDateBR}</div>
//                                                                                         <div class="col-12"><strong class="labelTask mr-1">Descrição do atendimento:</strong>{task.technicalDescription}</div>
//                                                                                         <div class="col-6"><strong class="labelTask mr-1">Encerrado por:</strong>{task.finishedUser.username}</div>
//                                                                                     </div>
//                                                                                 </div>
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                             }

//                                                             {/* <!-- PHOTO DATA --> */}
//                                                             {
//                                                                 isShowInfosPhotosAndMaterials &&
//                                                                 <div class="row-extra-data js-expand-row" data-ref="photos">
//                                                                     <div class="w-100">
//                                                                         {/* <!-- MATERIALS --> */}
//                                                                         <div class="col-12">
//                                                                             <div class="d-block mb-3">
//                                                                                 <div class="inline padding08">
//                                                                                     <div class="labelTaskTitle text-uppercase">Materiais</div>
//                                                                                 </div>
//                                                                                 <div class="line"></div>
//                                                                             </div>
//                                                                             <div class="card">
//                                                                                 <table class="table table-striped m-0 table-sm">
//                                                                                     <thead>
//                                                                                         <tr>
//                                                                                             <th class="p-1 text-dark" scope="col">Código</th>
//                                                                                             <th class="p-1 text-dark" scope="col">Material/Serviço</th>
//                                                                                             <th class="p-1 text-dark" scope="col">Quant</th>
//                                                                                             <th class="p-1 text-dark" scope="col">Unidade</th>
//                                                                                             <th class="p-1 text-dark" scope="col">Preço</th>
//                                                                                         </tr>
//                                                                                     </thead>
//                                                                                     {
//                                                                                         task &&
//                                                                                         task.materialHelpDesk &&
//                                                                                         task.materialHelpDesk.map((item, k) => (
//                                                                                             <tr key={k}>
//                                                                                                 <td>{item.material.id}</td>
//                                                                                                 <td>{item.material.name}</td>
//                                                                                                 <td>{item.quantity}</td>
//                                                                                                 <td>{item.material.measurement.measurement}</td>
//                                                                                                 <td>{item.material.price}</td>
//                                                                                             </tr>
//                                                                                         ))
//                                                                                     }
//                                                                                 </table>
//                                                                             </div>
//                                                                         </div>

//                                                                         {/* <!-- PHOTOS --> */}
//                                                                         {
//                                                                             task &&
//                                                                             task.photos &&
//                                                                             task.photos.map((item, k) => (
//                                                                                 <div class="col-12">
//                                                                                     <div class="d-block mb-3">
//                                                                                         <div class="inline padding08">
//                                                                                             <div class="labelTaskTitle text-uppercase">Fotos</div>
//                                                                                         </div>
//                                                                                         <div class="line"></div>
//                                                                                     </div>

//                                                                                     <div class="d-flex flex-wrap text-left mt-4">
//                                                                                         <img src={item.url} class="w-50 p-3 m-auto img-thumbnail" />
//                                                                                     </div>
//                                                                                 </div>
//                                                                             ))
//                                                                         }
//                                                                     </div>
//                                                                 </div>
//                                                             }
//                                                         </div>
//                                                     </td>
//                                                 </tr>
//                                             </>
//                                         ))
//                                     }
//                                 </tbody>
//                             </table>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div class="full20">
//                 <div class="full20 mainColorBK center whiteFont topBlueBorder">
//                     <div class="container row">
//                         <div class="row col-12">
//                             <div class="col-6">
//                                 <h6><img src="https://protocolosjjeletrica.com.br/static/media/logojj.f407aaf2.png" class="logoImg" /></h6>
//                             </div>
//                             <div class="col-6 text-right">
//                                 <h6>© {new Date().getFullYear()} Todos os direitos reservados</h6>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div >
//     )
// }