import { create } from 'apisauce'

const rest = create({
    // baseURL: 'http://127.0.0.1:8081',
    //baseURL: 'http://vps29049.publiccloud.com.br:8081',
    baseURL: 'http://vps29049.publiccloud.com.br:8081',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
    }
})

//Para tratativa de erros
//Executa depois de receber o resultado e antes de enviar para o response no metodo que o chamou
rest.addResponseTransform(response => {//Isso é um Middleware para resposta da API
    if (!response.ok) throw response //Assim vai para um cath que temos no method que chama o rest
});



//Middleware na requisição
rest.addAsyncRequestTransform(request => async () => {
    //Cidade do usuário
    const userFromStorage = localStorage.getItem('@lighthouse_jjeletrica:user')
    const user = JSON.parse(userFromStorage)
    request.headers['param_cityIdFromUser'] = user.city.id
    request.headers['param_token'] = user.token

    //Cidade para filtro
    const cityForFilter = localStorage.getItem('@lighthouse_jjeletrica:cityForFilter')
    request.headers['param_cityForFilter'] = cityForFilter

    //Recupera o token
    const token = await localStorage.getItem('@lighthouse_jjeletrica:token')
    if (token)
        request.headers['Authorization'] = `Bearer ${token}`
})

export default rest
