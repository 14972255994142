import React, { useState, useEffect } from 'react'

//Components
import FloatingField from '../../components/floatingField'
import SelectBox from '../../components/selectBox'
import ButtonRectangle from '../../components/buttonRectangle'
import OverMessage from '../../components/overMessage'
import restZipCode from '../../service/restApiZipCode'
import Footer from '../../components/footer'
import rest from '../../service/rest'
// import restFile from '../../service/restUpload'
import Inputmask from 'inputmask'
import Loading from '../../components/loadingBar'
import './styles.css'
import FullLoading from '../../components/fullLoading'

const HelpDesk = () => {
    const [isOpening, setIsOpening] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedOption, setSelectedOption] = useState(1)
    const [neighborhood, setNeighborhood] = useState('')
    const [selectedZipCode, setSelectedZipCode] = useState(0)
    const [street, setStreet] = useState('')
    const [city, setCity] = useState('')
    const [problem, setProblem] = useState('')
    const [protocolCode, setProtocolCode] = useState('')
    const [photos, setPhotos] = useState([])

    const [name, setName] = useState('')
    const [phone1, setPhone1] = useState('')
    const [phone2, setPhone2] = useState('')
    const [lot, setLot] = useState('')
    const [complement, setComplement] = useState('')
    const [refPoint, setRefPoint] = useState('')
    const [description, setDescription] = useState('')

    const [fileName, setFileName] = useState('')
    const [selectedFile, setSelectedFile] = useState(null)
    const [overMessage, setOverMessage] = useState({
        show: false,
        message: '',
        type: ''
    })
    const [tasks, setTasks] = useState({
        status: '',
        dateOpen: '',
        dateFinish: '',
        problem: ''
    })
    const [infosFromZipCode, setInfosFromZipCode] = useState({
        status: 0,
        currentZipCode: '',
        bairro: '',
        localidade: '',
        logradouro: '',
        uf: ''
    })
    const [citys, setCitys] = useState([])
    const [problems, setProblems] = useState([])
    const [formData, setFormData] = useState(new FormData())

    const selectOption = async (firstId, secondId) => {
        document.getElementById(firstId).classList.remove('helpDeskOption')
        document.getElementById(firstId).classList.add('helpDeskOptionActive')

        document.getElementById(secondId).classList.remove('helpDeskOptionActive')
        document.getElementById(secondId).classList.add('helpDeskOption')

        if (selectedOption == 1) {
            setSelectedOption(2)
        } else {
            setSelectedOption(1)
        }
    }

    const showOverMessageRequired = (fieldName) => {
        setOverMessage({
            show: true,
            message: 'Favor preencher todos os campos obrigatórios',
            type: 'error'
        })

        setTimeout(() => {
            setOverMessage({
                show: false,
                message: '',
                type: ''
            })
        }, 3000)
    }

    const selectImage = (e) => {
        setSelectedFile(e.target.files[0])
        setFileName(e.target.files[0].name)
    }

    const savePhotos = async () => {
        //e.preventDefault()
        let validName = document.getElementById('nameId').value
        let validPhone1Id = document.getElementById('phone1Id').value
        let validDescription = document.getElementById('descriptionId').value
        let validZipCode = document.getElementById('zipCodeId').value
        let validCity = document.getElementById('cityId').value
        let validProblem = document.getElementById('problemId').value
        let validNeighborhood = document.getElementById('neighborhoodId').value
        let validStreet = document.getElementById('streetId').value

        if (validName == '') {
            document.getElementById('nameId').classList.add('floatingInputRequired')
            showOverMessageRequired('nome')
        }

        if (validPhone1Id == '') {
            document.getElementById('phone1Id').classList.add('floatingInputRequired')
            showOverMessageRequired('Telefone1')
        }

        if (validDescription == '') {
            document.getElementById('descriptionId').classList.add('floatingInputRequired')
            showOverMessageRequired('Description1')
        }

        // if (validZipCode == '') {
        //     document.getElementById('zipCodeId').classList.add('floatingInputRequired')
        //     showOverMessageRequired('zipCode')
        // }

        if (validNeighborhood == '') {
            document.getElementById('neighborhoodId').classList.add('floatingInputRequired')
            showOverMessageRequired('neighborhood')
        }

        if (validCity == '') {
            document.getElementById('boxSelectId').classList.remove('borderGray')
            document.getElementById('boxSelectId').classList.add('floatingInputRequired')
            showOverMessageRequired('city')
        }

        if (validProblem == '') {
            document.getElementById('boxSelectProblemId').classList.remove('borderGray')
            document.getElementById('boxSelectProblemId').classList.add('floatingInputRequired')
            showOverMessageRequired('problem')
        }

        if (validStreet == '') {
            document.getElementById('streetId').classList.add('floatingInputRequired')
            showOverMessageRequired('street')
        }

        if (validName == '' || validPhone1Id == '' || validName == '' || validPhone1Id == '' || validDescription == '' || validCity == '' || validProblem == '' || validNeighborhood == '' || validStreet == '') {
        } else {
            // const files = document.getElementById('files')
            // const server = "http://phpserver.protocolosjjeletrica.com.br/uploadFiles.php";
            // const formData = new FormData()

            setIsLoading(true)

            let photos = []
            setIsLoading(false)
            save()
            // if (files.files[0] != null) {
            //     for (let i = 0; i < files.files.length; i++) {
            //         let file = files.files[i]
            //         let actualDate = new Date()
            //         let dateForName = ((actualDate.getDate() + '').length > 1 ? actualDate.getDate() + '_' : '0' + actualDate.getDate() + '_') +
            //             (((actualDate.getMonth() + 1) + '').length > 1 ? (actualDate.getMonth() + 1) : '0' + (actualDate.getMonth() + 1) + '_') +
            //             actualDate.getFullYear()

            //         let timeForName = ((actualDate.getHours() + '').length > 1 ? actualDate.getHours() + '_' : '0' + actualDate.getHours() + '_') +
            //             ((actualDate.getMinutes() + '').length > 1 ? actualDate.getMinutes() + '_' : '0' + actualDate.getMinutes() + '_') +
            //             ((actualDate.getSeconds() + '').length > 1 ? actualDate.getSeconds() : '0' + actualDate.getSeconds())

            //         photos.push({
            //             'path': 'http://phpserver.protocolosjjeletrica.com.br/uploadLhFiles/',
            //             'name': (dateForName + '_' + timeForName + '_' + i + (file.name.substring(file.name.lastIndexOf('.'), file.name.length)).toLowerCase()),
            //             'photoOrigin': 0
            //         })
            //         formData.append('file' + i, file)
            //         formData.append('newName' + i, dateForName + '_' + timeForName + '_' + i)
            //         if (i + 1 == files.files.length) {
            //             fetch(server, {
            //                 method: 'post',
            //                 body: formData
            //             }).then((res) => {
            //                 setIsLoading(false)
            //                 setPhotos(photos)
            //                 save(photos)
            //             }).catch((error) => {
            //                 setIsLoading(false)
            //             })
            //         }
            //     }
            // } else {
            //     setIsLoading(false)
            //     save()
            // }
        }
    }

    const save = async (photos = []) => {
        let validName = document.getElementById('nameId').value
        let validPhone1Id = document.getElementById('phone1Id').value
        let validDescription = document.getElementById('descriptionId').value
        let validZipCode = document.getElementById('zipCodeId').value
        let validCity = document.getElementById('cityId').value
        let validProblem = document.getElementById('problemId').value
        let validNeighborhood = document.getElementById('neighborhoodId').value
        let validStreet = document.getElementById('streetId').value

        let files = new FormData()
        files.append('files', selectedFile)

        if (validName == '') {
            document.getElementById('nameId').classList.add('floatingInputRequired')
            showOverMessageRequired('nome')
        }

        if (validPhone1Id == '') {
            document.getElementById('phone1Id').classList.add('floatingInputRequired')
            showOverMessageRequired('Telefone1')
        }

        if (validDescription == '') {
            document.getElementById('descriptionId').classList.add('floatingInputRequired')
            showOverMessageRequired('Description1')
        }

        // if (validZipCode == '') {
        //     document.getElementById('zipCodeId').classList.add('floatingInputRequired')
        //     showOverMessageRequired('zipCode')
        // }

        if (validNeighborhood == '') {
            document.getElementById('neighborhoodId').classList.add('floatingInputRequired')
            showOverMessageRequired('neighborhood')
        }

        if (validCity == '') {
            document.getElementById('boxSelectId').classList.remove('borderGray')
            document.getElementById('boxSelectId').classList.add('floatingInputRequired')
            showOverMessageRequired('city')
        }

        if (validProblem == '') {
            document.getElementById('boxSelectProblemId').classList.remove('borderGray')
            document.getElementById('boxSelectProblemId').classList.add('floatingInputRequired')
            showOverMessageRequired('problem')
        }

        if (validStreet == '') {
            document.getElementById('streetId').classList.add('floatingInputRequired')
            showOverMessageRequired('street')
        }

        if (validName == '' || validPhone1Id == '' || validName == '' || validPhone1Id == '' || validDescription == '' || validCity == '' || validProblem == '' || validNeighborhood == '' || validStreet == '') {
        } else {
            setIsLoading(true)

            //Salva as fotos
            // await savePhotos()

            let phones = []
            phones.push({
                contact: validPhone1Id,
                type: 1
            })
            if (document.getElementById('phone2Id').value != '' && document.getElementById('phone2Id').value != document.getElementById('phone1Id').value) {
                phones.push({
                    contact: document.getElementById('phone2Id').value,
                    type: 1
                })
            }
            try {
                const response = await rest.post('/helpdesk/v1/save', {
                    client: {
                        name: validName,
                        contacts: phones
                    },
                    address: {
                        zipCode: document.getElementById('zipCodeId').value,
                        city: { id: parseInt(document.getElementById('cityId').value) },
                        street: document.getElementById('streetId').value,
                        lot: document.getElementById('lotId').value,
                        complement: document.getElementById('complementId').value,
                        referencePoint: document.getElementById('refPointId').value,
                        neighborhood: document.getElementById('neighborhoodId').value
                    },
                    photos: photos,
                    problem: { id: parseInt(document.getElementById('problemId').value) },
                    description: document.getElementById('descriptionId').value,
                    protocol: ''
                })
                setProtocolCode(response.data.protocol)
                setOverMessage({
                    show: true,
                    message: 'Solicitação registrada com sucesso',
                    type: 'success'
                })
                setIsLoading(false)
                setTimeout(() => {
                    setOverMessage({
                        show: false,
                        message: '',
                        type: ''
                    })
                }, 3000)
            } catch (e) {
                setIsLoading(false)
                setOverMessage({
                    show: true,
                    message: 'Falha ao registrar',
                    type: 'error'
                })
            }
        }
    }

    // const findPhotos = async () => {
    //     const response = await rest.get('files/v1/one/listall')
    //     setPhotos(response.data)
    // }

    const findCitys = async () => {
        const response = await rest.get('/city/v1/findAll')
        setCitys(response.data)
    }

    const findProblems = async () => {
        const response = await rest.get('/problem/v1/findAll')
        setProblems(response.data)
    }

    const findInfosByCode = async () => {

        //Valida o campo
        let validFilterProtocolId = document.getElementById('filterProtocolId').value
        if (validFilterProtocolId == '') {
            setOverMessage({
                show: true,
                message: 'Protocolo inválido',
                type: 'warning'
            })

            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 3000)
        } else {

            setIsLoading(true)
            try {
                const response = await rest.get(`/helpdesk/v1/findbyprotocolcode/${document.getElementById('filterProtocolId').value}`)
                setTasks(response.data)

                if (response.data.length == 0) {
                    setOverMessage({
                        show: true,
                        message: 'Atendimento não encontrado',
                        type: 'warning'
                    })
                }
            } catch (e) {
                setOverMessage({
                    show: true,
                    message: 'Falha ao consultar atendimento',
                    type: 'error'
                })

                setTimeout(() => {
                    setOverMessage({
                        show: false,
                        message: '',
                        type: ''
                    })
                }, 3000)
            }
            setIsLoading(false)
        }
    }

    const findInfosByNameAndPhone = async () => {
        setIsLoading(true)
        try {
            //Valida o campo
            let validNameFilterId = document.getElementById('nameFilterId').value
            let validPhoneFilterId = document.getElementById('phoneFilterId').value
            if (validNameFilterId == '' || validPhoneFilterId == '') {
                setOverMessage({
                    show: true,
                    message: 'Favor informar nome e telefone',
                    type: 'warning'
                })

                setTimeout(() => {
                    setOverMessage({
                        show: false,
                        message: '',
                        type: ''
                    })
                }, 3000)
            } else {
                const response = await rest.get(`/helpdesk/v1/findbynameandphone/${document.getElementById('nameFilterId').value}/${document.getElementById('phoneFilterId').value}`)
                setTasks(response.data)

                if (response.data.length == 0) {
                    setOverMessage({
                        show: true,
                        message: 'Atendimento não encontrado',
                        type: 'warning'
                    })

                    setTimeout(() => {
                        setOverMessage({
                            show: false,
                            message: '',
                            type: ''
                        })
                    }, 3000)
                }
            }
        } catch (e) {
            setOverMessage({
                show: true,
                message: 'Falha ao consultar atendimento',
                type: 'error'
            })

            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 3000)
        }
        setIsLoading(false)
    }

    const startApp = async () => {
        setIsLoading(true)
        try {
            await findCitys()
            await findProblems()

            let fieldPhone1 = document.getElementById("phone1Id") ? document.getElementById("phone1Id") : null
            let fieldPhone2 = document.getElementById("phone2Id") ? document.getElementById("phone2Id") : null
            let fieldZipCode = document.getElementById("zipCodeId") ? document.getElementById("zipCodeId") : null
            let fieldPhoneFilter = document.getElementById("phoneFilterId") ? document.getElementById("phoneFilterId") : null

            var imPhone = new Inputmask("(99) 999999999")
            var imZipCode = new Inputmask("99999-999")
            if (fieldPhone1 != null) imPhone.mask(fieldPhone1)
            if (fieldPhone2 != null) imPhone.mask(fieldPhone2)
            if (fieldPhoneFilter != null) imPhone.mask(fieldPhoneFilter)
            if (fieldZipCode != null) imZipCode.mask(fieldZipCode)
        } catch (e) {
            setIsLoading(false)
            setOverMessage({
                show: true,
                message: 'Falha ao iniciar aplicação',
                type: 'error'
            })

            setTimeout(() => {
                setOverMessage({
                    show: false,
                    message: '',
                    type: ''
                })
            }, 3000)
        }
        setIsLoading(false)
    }

    useEffect(async () => {
        // findPhotos()
        setTimeout(() => {
            setIsOpening(false)
            startApp()
        }, 1000)
    }, [])

    useEffect(() => {
        startApp()
    }, [selectedOption])

    useEffect(() => {
        try {
            setStreet(infosFromZipCode.logradouro)
            setNeighborhood(infosFromZipCode.bairro)
            setCity(infosFromZipCode.localidade)
            //Reload na lista de cidades
            let updateCity = citys
            setCitys([])
            setCitys(updateCity)

            //Msg de acordo com o resultado da consulta do CEP
            if (infosFromZipCode.status == 404) {
                setOverMessage({
                    show: true,
                    message: 'CEP Não encontrado',
                    type: 'warning'
                })
                setTimeout(() => {
                    setOverMessage({
                        show: false,
                        message: '',
                        type: ''
                    })
                }, 4000)
            }

        } catch (e) {
        }
    }, [infosFromZipCode])

    useEffect(() => {
        document.getElementById('boxSelectId').classList.remove('floatingInputRequired')
        document.getElementById('boxSelectId').classList.add('borderGray')
    }, [city])

    useEffect(() => {
        document.getElementById('boxSelectProblemId').classList.remove('floatingInputRequired')
        document.getElementById('boxSelectProblemId').classList.add('borderGray')
    }, [problem])

    //Quando usar o bt de remover o arquivo
    // useEffect(() => {
    //     if (selectedFile == null)
    //         document.getElementById('files').value = null
    // }, [selectedFile])

    const getBase64 = async (data) => {
        return atob(data)
    }


    return (
        <div>
            {/* {
                isOpening && <FullLoading />
            } */}
            {
                isLoading && <Loading />
            }
            {/* {
                photos.map((item, key) => (
                    <div key={key}>
                        <img src={`data:image/jpeg;base64,${item.data}`} width='500' height='500' />
                    </div>
                ))
            } */}
            <OverMessage show={overMessage.show} message={overMessage.message} type={overMessage.type} />
            <div className="full bkHelpDeskx center padding30">
                <div className="secondColorBK smallShadow imgCardBox radius12 use100 padding30 container delay3">
                    <div className="row center">
                        <div className="col-12 text-center padding10">
                            {
                                protocolCode != '' ?
                                    <h3>Protocolo: {protocolCode}</h3>
                                    :
                                    <h1>Atendimentos</h1>
                            }
                            {/* <h5>Agradeçemos a preferência</h5> */}
                        </div>
                        {
                            protocolCode == '' && <div>
                                <div className="col-12">
                                    <div className="row padding10">
                                        <div id="newHelpDesk" className="col-6 text-center helpDeskOptionActive delay" onClick={e => selectOption('newHelpDesk', 'findHelpDesk')}>
                                            Registrar Atendimento
                                        </div>
                                        <div id="findHelpDesk" className="col-6 text-center helpDeskOption delay" onClick={e => selectOption('findHelpDesk', 'newHelpDesk')}>
                                            Consultar atendimento
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    {
                                        selectedOption == 1 ? (
                                            <div className="row">
                                                <div className="col-12 text-center">
                                                    <h5>Registrar um novo chamado</h5>
                                                </div>
                                                <div className="col-12">
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6">
                                                                <FloatingField id="nameId" label="Nome" value={name} setValue={setName} required />
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <FloatingField id="phone1Id" label="Telefone" value={phone1} setValue={setPhone1} required />
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <FloatingField id="phone2Id" label="Telefone 2" value={phone2} setValue={setPhone2} />
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <FloatingField id="zipCodeId" label="CEP" type="zipcode" lst={citys} setIsLoading={setIsLoading} setInfosFromZipCode={setInfosFromZipCode} />
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                {/* <SelectBox id="cityId" label="Cidade" lst={citys} type='city' uf={infosFromZipCode.uf} loc={infosFromZipCode.localidade} /> */}
                                                                <div className="boxSelect">
                                                                    {
                                                                        <div id='boxSelectId' className='borderGray'>
                                                                            <label for='' id='' className="labelSelect">Cidade*</label><br />
                                                                            <div id='boxSelect'>
                                                                                <select name="city" id="cityId" className="selectOptionsBox borderNone" onChange={(e) => setCity(e.target.value)}>
                                                                                    <option value='' className="selectOption blackFont">Selecionar Cidade</option>
                                                                                    {
                                                                                        citys != null && (
                                                                                        citys.map((item, k) => {
                                                                                            return (
                                                                                                item.name == city ?
                                                                                                    <option key={k} value={item.id} className="selectOption blackFont" selected>{item.name} - {item.uf}</option>
                                                                                                    :
                                                                                                    <option key={k} value={item.id} className="selectOption blackFont">{item.name} - {item.uf}</option>
                                                                                            )
                                                                                        })
                                                                                        )
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                {
                                                                    infosFromZipCode.logradouro != '' ?
                                                                        <FloatingField id="streetId" label="Rua" value={street} setValue={setStreet} required />
                                                                        :
                                                                        <FloatingField id="streetId" label="Rua" value={street} setValue={setStreet} required />
                                                                }
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <FloatingField id="lotId" label="Lote" value={lot} setValue={setLot} />
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <FloatingField id="complementId" label="Complemento" value={complement} setValue={setComplement} />
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <FloatingField id="refPointId" label="Ponto de referência" value={refPoint} setValue={setRefPoint} />
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                {
                                                                    infosFromZipCode.logradouro != '' ?
                                                                        <FloatingField id="neighborhoodId" label="Bairro" value={neighborhood} setValue={setNeighborhood} required />
                                                                        :
                                                                        <FloatingField id="neighborhoodId" label="Bairro" value={neighborhood} setValue={setNeighborhood} required />
                                                                }
                                                            </div>
                                                            {/* <div className="col-12 col-sm-6">
                                                        <SelectBox id="problemId" label="Problema" lst={problems} />
                                                    </div> */}
                                                            <div className="col-12 col-sm-6">
                                                                {/* <SelectBox id="cityId" label="Cidade" lst={citys} type='city' uf={infosFromZipCode.uf} loc={infosFromZipCode.localidade} /> */}
                                                                <div className="boxSelect">
                                                                    {
                                                                        <div id='boxSelectProblemId' className='borderGray'>
                                                                            <label for='' id='' className="labelSelect">Problema*</label><br />
                                                                            <select name="problem" id="problemId" className="selectOptionsBox borderNone" onChange={(e) => setProblem(e.target.value)}>
                                                                                <option value='' className="selectOption blackFont">Selecionar Problema</option>
                                                                                {
                                                                                    problems.map((item, k) => {
                                                                                        return (
                                                                                            item.problem == problem ?
                                                                                                <option key={k} value={item.id} className="selectOption blackFont" selected>{item.problem}</option>
                                                                                                :
                                                                                                <option key={k} value={item.id} className="selectOption blackFont">{item.problem}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <FloatingField id="descriptionId" label="Descrição" value={description} setValue={setDescription} required />
                                                            </div>
                                                            {/* <div className="col-12 col-sm-6">
                                                                <div className="fileUpload whiteFont center delay2">
                                                                    <label for="files" className="fileUploadInside">
                                                                        <h5>Adicionar Foto</h5>
                                                                    </label>
                                                                    {/* <input type="file" id="files" accept='image/*' className="fileUpload" name="files" multiple onChange={(f) => addPhotos(f)} /> * /}
                                                                    <input type="file" id="files" accept="image/*" className="fileUpload" name="files" onChange={(e) => selectImage(e)} />
                                                                </div>
                                                                {
                                                                    selectedFile &&
                                                                    <div for="" className="row">
                                                                        <h5>{fileName}</h5><span className="roundedOutlineRedBtn center delay" onClick={(e) => setSelectedFile(null)}>x</span>
                                                                    </div>
                                                                }
                                                            </div> */}
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 toRight1 top40 bottom30">
                                                                <div onClick={e => savePhotos()}>
                                                                    <ButtonRectangle label="Salvar" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                            :
                                            (
                                                <div className="row padding10">
                                                    <div className="col-12 text-center">
                                                        <h5>Consultar um chamado</h5>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className='col-12'>
                                                                <label for='' id='' className="labelSelect">Consultar por protocolo</label><br />
                                                            </div>
                                                            <input id="filterProtocolId" className="floatingInput" type="text" placeholder="Protocolo" />
                                                            <div className="btBlueSmall w10 center" onClick={e => findInfosByCode()}>
                                                                <i className="pi pi-search"></i>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className='col-12'>
                                                                <label for='' id='' className="labelSelect">Consultar por nome e telefone</label><br />
                                                            </div>
                                                            <div className="col-6">
                                                                <input id="nameFilterId" className="floatingInput" type="text" placeholder="Nome" />
                                                            </div>
                                                            <div className="col-6">
                                                                <input id="phoneFilterId" className="floatingInput" type="text" placeholder="Telefone" />
                                                                <div className="btBlueSmall w10 center" onClick={e => findInfosByNameAndPhone()}>
                                                                    <i className="pi pi-search"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="container-fluid">
                                                            {
                                                                tasks && (
                                                                    tasks.length > 0 &&
                                                                    tasks.map((t, k) => {
                                                                        return <div className="row top10" key={k}>
                                                                            <div className="col-12 mainColorBk whiteFont borderRadius04">
                                                                                Protocolo: {t.protocol}
                                                                            </div>
                                                                            <div className="col-12 col-sm-6">
                                                                                <FloatingField id={`code_` + t.id} label="Protocolo" value={t.protocol} readOnly />
                                                                            </div>
                                                                            <div className="col-12 col-sm-6">
                                                                                <FloatingField id={`code_status_` + t.id} label="Status" value={t.statusHelpDesk.status} readOnly />
                                                                            </div>
                                                                            <div className="col-12 col-sm-6">
                                                                                <FloatingField id={`code_problem_` + t.id} label="Problema" value={t.problem.problem} readOnly />
                                                                            </div>
                                                                            <div className="col-12 col-sm-6">
                                                                                <FloatingField id={`code_dateopen_` + t.id} label="Data de Abertura" value={t.openedDateBR} readOnly />
                                                                            </div>
                                                                            <div className="col-12 col-sm-6 bottom30">
                                                                                <FloatingField id={`code_datefinish_` + t.id} label="Data de conclusão" value={t.finishDateBR} readOnly />
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                )
                                                            }

                                                            {/* <div className="row">
                                                            <div className="col-12 toRight1 top40 bottom30">
                                                                <div onClick={e => this.save()}>
                                                                    <ButtonRectangle label="Salvar" />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default HelpDesk