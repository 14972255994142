import React, { useEffect, useState } from 'react';
import './styles.css'

import loadingImage from '../../imgs/ld-logo-1.png'

function LoadingBar({ show }) {
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (show) {
            //Exibe o component de loading
            setIsLoading(true)
        } else {
            //Component
            let e = document.getElementById('loadingBarBoxID')
            if (e) {
                e.classList.add('outAnimation')//Adiciona a animação de saida do component
                setTimeout(() => {
                    //Remove a classe de animação de saida
                    e.classList.remove('outAnimation')
                    //Remove o elemento da tela
                    setIsLoading(false)
                }, 400);
            }
        }
    }, [show])

    return (
        <div>
            {
                isLoading && <div id='loadingBarBoxID' className="full blackMask06 over center">
                    {/* <div class="imageLoading"></div> */}
                    <img src={loadingImage} class="imageLoading" />
                </div>
            }
        </div>
    );
}

export default LoadingBar